import React, { Component } from "react"
import Head from "../../head"
import Layout from "../../layout/layout.higherComponent"
import WebAppTitle from "./WebAppPageTitle/WebAppTitle"
import ClientsServices from "../servicesHomePage/servicesClients/clientsServices"
import TalkToOurTeam from "../../homepageInnos/about/TalkToOurTeam"
import OurWebApp from "./ourWebAppServices/ourWebAppServices"
import TheLastTech from "../mobileApp/theLastTech/theLastTech"
import OurWebAppProcess from "./ourWebAppProcess/ourWebAppProcess"
// import Contact from "../../homepageInnos/contact/contact.component"

class WebAppPage extends Component {
  constructor(props) {
    super(props)
    this.statsRef = React.createRef()
    this.statsRef2 = React.createRef()
  }
  handleScroll = e => {
    e.preventDefault()
    window.scrollTo({
      top: this.statsRef.current.offsetTop,
      behavior: "smooth"
    })
  }
  handleScroll2 = e => {
    e.preventDefault()
    window.scrollTo({
      top: this.statsRef2.current.offsetTop,
      behavior: "smooth"
    })
  }

  render() {
    return (
      <Layout>
        <Head title="Web Applications Development" />
        <WebAppTitle />
        <ClientsServices />
        <TalkToOurTeam />
        <OurWebApp />
        <TheLastTech />
        <OurWebAppProcess />

        {/* <div style={{ paddingTop: "70px" }}>
          <Contact />
        </div> */}
      </Layout>
    )
  }
}
export default WebAppPage
