import React from "react"
import WebAppPage from "../components/servicesPage/webApp/webAppPage";

const WebApp = () => {
  return (
    <WebAppPage/>
  )
}

export default WebApp
