import React from "react"
import { withTranslation } from "react-i18next"
// import { dataOurWebAppProcess } from "../../../../../data/Services/dataWebapp"
const ContentWebApp = props => {
  const { t } = props
  return (
    <>
      {t("src.pages.webDevelopmentPage.ourWebProcess.data").map(
        (block, index) => (
          <div key={index.toString()} className="col">
            <header className="col-header">
              <div className={`header-title ${props.show}`}>{block.title}</div>
            </header>
            <footer className="col-footer">
              <ul className="footer-list">
                {block.content.map((ct, idx) => (
                  <li key={idx.toString()}>{ct}</li>
                ))}
              </ul>
            </footer>
          </div>
        )
      )}
    </>
  )
}

export default withTranslation()(ContentWebApp)
