import React from "react"
import { withTranslation } from "react-i18next"
// import { dataWebTitle } from "../../../../data/Services/dataWebapp"
import "./WebAppTitle.scss"

const WebAppTitle = props => {
  const { t } = props
  return (
    <section className="hero hero--600 bgWebApp">
      <div className="container">
        <div className="hero__content">
          <h1 className="title title--uppercase">
            <span className="title__preheader title__preheader--tag">
              {t("src.pages.webDevelopmentPage.title")}
            </span>
            {t("src.pages.webDevelopmentPage.subtitle")}
          </h1>
          <p className="hero__lead">
            {t("src.pages.webDevelopmentPage.content")}
          </p>
        </div>
      </div>
    </section>
  )
}

export default withTranslation()(WebAppTitle)
